// extracted by mini-css-extract-plugin
export var FlexCard = "index-module--FlexCard--AyvQx";
export var FlexCard__content = "index-module--FlexCard__content--+LHUa";
export var FlexCard__content__flex = "index-module--FlexCard__content__flex--tjaM6";
export var FlexCard__content__flex__img = "index-module--FlexCard__content__flex__img--Y0cLy";
export var FlexCard__content__img = "index-module--FlexCard__content__img--kXO5r";
export var FlexCard__content__imgContainer = "index-module--FlexCard__content__imgContainer--V+6gT";
export var FlexCard__content__imgRound = "index-module--FlexCard__content__imgRound--XvINc";
export var FlexCard__content__text = "index-module--FlexCard__content__text--ht0cC";
export var FlexCard__textFlex = "index-module--FlexCard__textFlex--jHPjS";
export var listItem = "index-module--listItem--j7y7O";
export var pageFlex = "index-module--pageFlex--BY4jm";
export var pageNav = "index-module--pageNav--zmZcf";
export var pageText = "index-module--pageText--Js37P";
export var pageText__content = "index-module--pageText__content--Ktc60";
export var pageText__flex = "index-module--pageText__flex--QzXFq";
export var pageText__header = "index-module--pageText__header--S6RKH";
export var pageText__list = "index-module--pageText__list--m0+yu";
export var pageText__passage = "index-module--pageText__passage--ew-8T";
export var textCenter = "index-module--textCenter--++JQ-";